"use client";

import React, { useState } from "react";
import { Button, Input, Checkbox, Link, Divider } from "@nextui-org/react";
import { Icon } from "@iconify/react";
import { useAuth } from '../hooks/useAuth';
import { useNavigate } from 'react-router-dom';

export default function SignUp() {
  const { register } = useAuth();
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleEmailSignUp = async (e) => {
    e.preventDefault();
    try {
      const result = await register(email, password);
      if (result.success) {
        navigate('/dashboard');
      } else {
        console.error('Registration failed:', result.error);
        // Optionally display error message to the user
      }
    } catch (error) {
      console.error('Registration failed:', error);
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen w-full bg-background">
      <div className="flex h-full w-full items-center justify-center">
        <div className="flex w-full max-w-sm flex-col gap-4 rounded-large bg-content1 px-8 pb-10 pt-6 shadow-small">
          <h1 className="mb-4 text-xl font-medium">Sign Up</h1>
          <form className="flex flex-col gap-y-3" onSubmit={handleEmailSignUp}>
            <Input
              autoFocus
              label="Email Address"
              name="email"
              type="email"
              variant="bordered"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />

            <Input
              label="Password"
              name="password"
              type="password"
              variant="bordered"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />

            <Button color="primary" type="submit">
              Sign Up
            </Button>
          </form>
          <Divider />
          <p className="text-center">
            Already have an account?{' '}
            <Link color="primary" href="/login">
              Log In
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
}