import React, { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Card, CardBody, CardFooter, Button, Tooltip, Checkbox, Divider, Dropdown, DropdownTrigger, DropdownMenu, DropdownItem, Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Textarea, Spacer, Text } from "@nextui-org/react";
import { Icon } from "@iconify/react";
import api from '../api';
import Toolbar from './Toolbar';
import PostForm from './PostForm';
import { fetchPosts, fetchFacebookPosts, setTranslateModalOpen, setAIOutputFieldPrompt, setAITypeFieldPrompt, translatePosts, updatePostFinalCopy, setPostFormOpen, setEditingPost, updatePost, createPost } from '../store/postsSlice';
import debounce from 'lodash/debounce';

const POST_TYPES = ['sale', 'promo', 'event', 'update'];

const PostEditor = () => {
  const dispatch = useDispatch();
  const { collectionId } = useParams();
  const posts = useSelector(state => state.posts.items);
  const loading = useSelector(state => state.posts.status === 'loading');
  const error = useSelector(state => state.posts.error);
  const isTranslateModalOpen = useSelector(state => state.posts.isTranslateModalOpen);
  const aiOutputFieldPrompt = useSelector(state => state.posts.aiOutputFieldPrompt);
  const aiTypeFieldPrompt = useSelector(state => state.posts.aiTypeFieldPrompt);
  const isPostFormOpen = useSelector(state => state.posts.isPostFormOpen);
  const editingPost = useSelector(state => state.posts.editingPost);
  const [editedFinalCopies, setEditedFinalCopies] = useState({});

  useEffect(() => {
    dispatch(fetchPosts(collectionId)).then((action) => {
      if (fetchPosts.fulfilled.match(action)) {
        const updatedPosts = action.payload;
        setEditedFinalCopies(prev => {
          const newState = { ...prev };
          updatedPosts.forEach(post => {
            if (newState[post._id] === undefined) {
              newState[post._id] = post.finalCopy;
            }
          });
          return newState;
        });
      }
    });
  }, [dispatch, collectionId]);

  const handleCreatePost = () => {
    dispatch(setEditingPost(null));
    dispatch(setPostFormOpen(true));
  };

  const handleFetchFacebookPosts = () => {
    dispatch(fetchFacebookPosts(collectionId));
  };

  const handleTranslateAllPosts = () => {
    dispatch(setTranslateModalOpen(true));
  };

  const handleConfirmTranslate = () => {
    dispatch(translatePosts({ 
      collectionId, 
      aiOutputFieldPrompt, 
      aiTypeFieldPrompt 
    }));
    dispatch(setTranslateModalOpen(false));
  };

  const handleCloseTranslateModal = () => {
    dispatch(setTranslateModalOpen(false));
  };

  const handlePromptChange = (newPrompt) => {
    dispatch(setAIOutputFieldPrompt(newPrompt));
  };

  const handleIncludeChange = async (postId, isChecked) => {
    try {
      await api.patch(`/api/posts/${postId}`, { include: isChecked });
      dispatch(fetchPosts(collectionId)); // Refresh posts after update
    } catch (error) {
      console.error('Error updating post include status:', error);
    }
  };

  const handleTypeChange = async (postId, newType) => {
    try {
      await api.patch(`/api/posts/${postId}`, { type: newType });
      dispatch(fetchPosts(collectionId)); // Refresh posts after update
    } catch (error) {
      console.error('Error updating post type:', error);
    }
  };

  const handleEditPost = (post) => {
    dispatch(setEditingPost(post));
    dispatch(setPostFormOpen(true));
  };

  const handleDeletePost = async (postId) => {
    try {
      await api.delete(`/api/posts/${postId}`);
      dispatch(fetchPosts(collectionId)); // Refresh posts after deletion
    } catch (error) {
      console.error('Error deleting post:', error);
    }
  };

  const handleOutputPromptChange = (newPrompt) => {
    dispatch(setAIOutputFieldPrompt(newPrompt));
  };

  const handleTypePromptChange = (newPrompt) => {
    dispatch(setAITypeFieldPrompt(newPrompt));
  };

  const handleEditFinalCopy = (postId, finalCopy) => {
    setEditedFinalCopies(prev => ({ ...prev, [postId]: finalCopy }));
  };

  const handleSaveFinalCopy = async (postId) => {
    try {
      await api.patch(`/api/posts/${postId}`, { finalCopy: editedFinalCopies[postId] });
      dispatch(fetchPosts(collectionId)); // Refresh posts after update
    } catch (error) {
      console.error('Error updating final copy:', error);
    }
  };

  const handleCancelEdit = () => {
    setEditedFinalCopies({});
  };

  const debouncedUpdateFinalCopy = useCallback(
    debounce((postId, finalCopy) => {
      dispatch(updatePostFinalCopy({ postId, finalCopy }));
    }, 1000),
    [dispatch]
  );

  const handleFinalCopyChange = (postId, finalCopy) => {
    setEditedFinalCopies(prev => ({ ...prev, [postId]: finalCopy }));
    debouncedUpdateFinalCopy(postId, finalCopy);
  };

  const handleCancelPostForm = () => {
    dispatch(setPostFormOpen(false));
    dispatch(setEditingPost(null));
  };

  const handleSubmitPost = async (postData) => {
    try {
      if (postData._id) {
        await dispatch(updatePost(postData)).unwrap();
      } else {
        await dispatch(createPost({ ...postData, collectionId })).unwrap();
      }
      dispatch(setPostFormOpen(false));
      dispatch(fetchPosts(collectionId));
    } catch (error) {
      console.error('Error submitting post:', error);
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className="space-y-4">
      <Toolbar
        onCreateItem={handleCreatePost}
        onFetchFacebookPosts={handleFetchFacebookPosts}
        onTranslateAllPosts={handleTranslateAllPosts}
      />
      {posts.map((post) => (
        <Card key={post._id} className="w-full">
          <CardBody className="space-y-2">
            <div className="flex justify-between items-center">
              <h3 className="text-lg font-semibold">{post.businessId?.name || 'N/A'}</h3>
              <Checkbox 
                isSelected={post.include} 
                onValueChange={(isChecked) => handleIncludeChange(post._id, isChecked)}
              >
                Include
              </Checkbox>
            </div>
            <Divider className="my-1" />
            <div className="grid grid-cols-2 gap-2 text-sm">
              <Dropdown>
                <DropdownTrigger>
                  <Button variant="bordered" size="sm">
                    Type: {post.type || 'Select'}
                  </Button>
                </DropdownTrigger>
                <DropdownMenu 
                  aria-label="Post Type Selection" 
                  onAction={(key) => handleTypeChange(post._id, key)}
                >
                  {POST_TYPES.map((type) => (
                    <DropdownItem key={type}>{type}</DropdownItem>
                  ))}
                </DropdownMenu>
              </Dropdown>
              <div>
                <span className="font-semibold">AI Type:</span> {post.aiDeterminedType || 'N/A'}
              </div>
            </div>
            <Divider className="my-1" />
            <div className="text-sm">
              <p className="font-semibold mb-1">Raw Biz Content:</p>
              <p className="whitespace-pre-wrap">{post.rawBizContent || 'N/A'}</p>
            </div>
            <Divider className="my-1" />
            <div className="text-sm">
              <p className="font-semibold mb-1">AI Output:</p>
              <p className="whitespace-pre-wrap">{post.aiOutput || 'N/A'}</p>
            </div>
            <Divider className="my-1" />
            <div className="text-sm">
              <p className="font-semibold mb-1">Final Copy:</p>
              <Textarea
                value={editedFinalCopies[post._id] !== undefined ? editedFinalCopies[post._id] : post.finalCopy || ''}
                onChange={(e) => handleFinalCopyChange(post._id, e.target.value)}
                placeholder="Enter final copy"
                rows={4}
                width="100%"
              />
            </div>
          </CardBody>
          <CardFooter className="justify-end space-x-2">
            <Button color="primary" variant="light" size="sm" onPress={() => handleEditPost(post)}>
              Edit
            </Button>
            <Tooltip content="Delete post">
              <Button
                isIconOnly
                color="danger"
                variant="light"
                size="sm"
                onPress={() => handleDeletePost(post._id)}
              >
                <Icon icon="mdi:trash-can-outline" width={18} />
              </Button>
            </Tooltip>
          </CardFooter>
        </Card>
      ))}
      <TranslateModal
        isOpen={isTranslateModalOpen}
        onClose={handleCloseTranslateModal}
        outputPrompt={aiOutputFieldPrompt}
        typePrompt={aiTypeFieldPrompt}
        onOutputPromptChange={handleOutputPromptChange}
        onTypePromptChange={handleTypePromptChange}
        onTranslate={handleConfirmTranslate}
      />
      <PostForm
        isOpen={isPostFormOpen}
        onClose={handleCancelPostForm}
        onSubmit={handleSubmitPost}
        initialData={editingPost}
      />
    </div>
  );
};

const TranslateModal = ({ 
  isOpen, 
  onClose, 
  outputPrompt, 
  typePrompt, 
  onOutputPromptChange, 
  onTypePromptChange,
  onTranslate 
}) => {
  return (
    <Modal 
      isOpen={isOpen} 
      onClose={onClose}
      size="3xl"
    >
      <ModalContent>
        <ModalHeader>Translate Posts</ModalHeader>
        <ModalBody>
          <Textarea
            label="AI Output Field Prompt"
            placeholder="Enter your AI output field prompt here"
            value={outputPrompt}
            onChange={(e) => onOutputPromptChange(e.target.value)}
            rows={12}
            width="100%"
          />
          <p className="text-sm text-gray-500 mt-2">
            Note: {'{{'} rawBizContent {'}}'}  and {'{{'} bizWebsite {'}}'}  will be replaced with actual post content and business website for each post.
          </p>
          <Spacer y={2} />
          <Textarea
            label="AI Type Field Prompt"
            placeholder="Enter your AI type field prompt here"
            value={typePrompt}
            onChange={(e) => onTypePromptChange(e.target.value)}
            rows={10}
            width="100%"
          />
        </ModalBody>
        <ModalFooter>
          <Button color="danger" variant="light" onPress={onClose}>
            Cancel
          </Button>
          <Button color="primary" onPress={onTranslate}>
            Translate Posts
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default PostEditor;
