import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import api from '../api';
import { Card, CardBody, Input, Textarea, Button, Spacer } from "@nextui-org/react";

const TemplateEditor = () => {
  const [template, setTemplate] = useState({ name: '', subject: '', htmlContent: '' });
  const [error, setError] = useState('');
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      fetchTemplate();
    }
  }, [id]);

  const fetchTemplate = async () => {
    try {
      const response = await api.get(`/api/email-templates/${id}`);
      setTemplate(response.data.data);
    } catch (error) {
      console.error('Error fetching template:', error);
      setError(error.response?.data?.message || 'Failed to fetch template');
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setTemplate({ ...template, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (id) {
        await api.put(`/api/email-templates/${id}`, template);
      } else {
        await api.post('/api/email-templates', template);
      }
      navigate('/templates');
    } catch (error) {
      console.error('Error saving template:', error);
      setError(error.response?.data?.message || 'Failed to save template');
    }
  };

  return (
    <div className="max-w-2xl mx-auto p-4">
      <Card>
        <CardBody>
          <h1 className="text-2xl font-bold mb-4">{id ? 'Edit' : 'Create'} Email Template</h1>
          {error && <p className="text-red-500 mb-4">{error}</p>}
          <form onSubmit={handleSubmit}>
            <Input
              label="Name"
              name="name"
              value={template.name}
              onChange={handleInputChange}
              required
            />
            <Spacer y={2} />
            <Input
              label="Subject"
              name="subject"
              value={template.subject}
              onChange={handleInputChange}
              required
            />
            <Spacer y={2} />
            <Textarea
              label="Body"
              name="htmlContent"
              value={template.htmlContent}
              onChange={handleInputChange}
              required
              minRows={5}
            />
            <Spacer y={2} />
            <Button type="submit" color="primary">
              Save Template
            </Button>
          </form>
        </CardBody>
      </Card>
    </div>
  );
};

export default TemplateEditor;
