import React, { useState } from 'react';
import { Card, Input, Button, Link } from "@nextui-org/react";
import api from '../api';

export default function ForgotPassword() {
  const [email, setEmail] = useState('');
  const [status, setStatus] = useState('idle');
  const [message, setMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus('loading');
    
    try {
      const response = await api.post('/api/auth/forgot-password', { email });
      setMessage(response.data.message);
      setStatus('success');
    } catch (error) {
      setMessage(error.response?.data?.message || 'An error occurred');
      setStatus('error');
    }
  };

  return (
    <div className="flex min-h-screen items-center justify-center bg-background">
      <Card className="w-full max-w-sm p-6">
        <h1 className="text-xl font-medium mb-4">Reset Password</h1>
        
        {status === 'success' ? (
          <div className="text-center">
            <p className="text-success mb-4">{message}</p>
            <Link href="/login" color="primary">
              Return to Login
            </Link>
          </div>
        ) : (
          <form onSubmit={handleSubmit} className="flex flex-col gap-4">
            <Input
              autoFocus
              label="Email Address"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            
            {status === 'error' && (
              <p className="text-danger text-sm">{message}</p>
            )}
            
            <Button 
              color="primary" 
              type="submit"
              isLoading={status === 'loading'}
            >
              Send Reset Link
            </Button>
            
            <div className="text-center mt-4">
              <Link href="/login" color="primary">
                Back to Login
              </Link>
            </div>
          </form>
        )}
      </Card>
    </div>
  );
}
