import api from './index'; // Import the centralized Axios instance

// Fetch all users
export const getUsers = async () => {
  try {
    const response = await api.get('/api/users'); // Prepend '/api'
    return response.data; // Returns the users array directly
  } catch (error) {
    console.error('Error fetching users:', error);
    throw error;
  }
};

// Create a new user
export const createUser = async (userData) => {
  try {
    const response = await api.post('/api/users', userData); // Prepend '/api'
    return response.data;
  } catch (error) {
    console.error('Error creating user:', error);
    throw error;
  }
};

// Check if a user is registered
export const isUserRegistered = async (uid) => {
  try {
    console.log('Checking if user is registered:', uid);
    const response = await api.get(`/api/users/check/${uid}`); // Prepend '/api'
    console.log('User check response:', response.status);
    return response.data.isRegistered;
  } catch (error) {
    console.error('Error checking user registration:', error);
    throw error;
  }
};

// Fetch a single user by ID
export const getUser = async (userId) => {
  try {
    const response = await api.get(`/api/users/${userId}`); // Prepend '/api'
    return response.data;
  } catch (error) {
    console.error('Error fetching user:', error);
    throw error;
  }
};

// Update a user by ID
export const updateUser = async (userId, userData) => {
  try {
    const response = await api.put(`/api/users/${userId}`, userData); // Prepend '/api'
    return response.data;
  } catch (error) {
    console.error('Error updating user:', error);
    throw error;
  }
};