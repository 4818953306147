import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchBusinesses, createBusiness, updateBusiness, deleteBusiness } from '../store/businessesSlice';
import { Table, TableHeader, TableColumn, TableBody, TableRow, TableCell, Button, Tooltip, Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Input, Textarea, Select, SelectItem } from "@nextui-org/react";
import { Icon } from '@iconify/react';
import api from '../api';

const Businesses = () => {
  const dispatch = useDispatch();
  const { items: businesses, status, error } = useSelector((state) => state.businesses);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [newBusiness, setNewBusiness] = useState({
    name: '',
    description: '',
    website: '',
    facebookURL: '',
    instagramURL: '',
    XURL: '',
    zipCode: ''
  });
  const [editingBusiness, setEditingBusiness] = useState(null);
  const [facebookAccesses, setFacebookAccesses] = useState([]);

  useEffect(() => {
    if (status === 'idle') {
      dispatch(fetchBusinesses());
    }
  }, [status, dispatch]);

  const handleCreateBusiness = async () => {
    try {
      await dispatch(createBusiness(newBusiness)).unwrap();
      setNewBusiness({
        name: '',
        description: '',
        website: '',
        facebookURL: '',
        instagramURL: '',
        XURL: '',
        zipCode: ''
      });
      setIsCreateModalOpen(false);
    } catch (error) {
      console.error('Failed to create the business:', error);
    }
  };

  const handleEditBusiness = async () => {
    if (!editingBusiness) return;
    try {
      await Promise.all([
        dispatch(updateBusiness({ 
          id: editingBusiness._id, 
          businessData: editingBusiness 
        })),
        api.put(`/api/facebook-access/associate/${editingBusiness._id}`, {
          facebookAccessId: editingBusiness.facebookAccessId || null
        }).catch(error => {
          console.error('Failed to update Facebook association:', error);
          throw error;
        })
      ]);
      setIsEditModalOpen(false);
    } catch (error) {
      console.error('Failed to update the business:', error);
    }
  };

  const handleDeleteBusiness = async (businessId) => {
    try {
      await dispatch(deleteBusiness(businessId)).unwrap();
    } catch (error) {
      console.error('Failed to delete the business:', error);
    }
  };

  const columns = [
    { name: "NAME", uid: "name" },
    { name: "DESCRIPTION", uid: "description" },
    { name: "ZIP CODE", uid: "zipCode" },
    { name: "ACTIONS", uid: "actions" }
  ];

  const renderCell = (business, columnKey) => {
    const handleEdit = async () => {
      try {
        const [businessResponse, allAccessesResponse] = await Promise.all([
          api.get(`/api/businesses/${business._id}`),
          api.get('/api/facebook-access/all')
        ]);
        
        const facebookResponse = await api.get(`/api/facebook-access/business/${business._id}`)
          .catch(() => ({ data: { data: null } }));
        
        const freshBusinessData = businessResponse.data.data;
        const facebookData = facebookResponse.data.data;
        setFacebookAccesses(allAccessesResponse.data.data);
        
        setEditingBusiness({
          ...freshBusinessData,
          facebookAccessId: facebookData?._id || ''
        });
        
        setIsEditModalOpen(true);
      } catch (error) {
        console.error('Error fetching business details:', error);
      }
    };

    switch (columnKey) {
      case "name":
        return <div>{business.name}</div>;
      case "description":
        return <div>{business.description}</div>;
      case "zipCode":
        return <div>{business.zipCode}</div>;
      case "actions":
        return (
          <div className="flex justify-center gap-2">
            <Button 
              size="sm" 
              color="primary"
              onPress={handleEdit}
            >
              Edit
            </Button>
            <Button 
              size="sm" 
              color="danger" 
              onPress={() => handleDeleteBusiness(business._id)}
            >
              Delete
            </Button>
          </div>
        );
      default:
        return business[columnKey] || "N/A";
    }
  };

  const fetchFacebookAccesses = async () => {
    try {
      const response = await api.get('/api/facebook-access/all');
      setFacebookAccesses(response.data.data);
    } catch (error) {
      console.error('Error fetching Facebook accesses:', error);
    }
  };

  if (status === 'loading') return <div>Loading...</div>;
  if (status === 'failed') return <div>{error}</div>;

  return (
    <div>
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-2xl font-bold">Businesses</h1>
        <Button onPress={() => setIsCreateModalOpen(true)} color="primary">
          <Icon icon="mdi:plus" width={20} />
          Add New Business
        </Button>
      </div>

      <Table
        aria-label="Businesses Table"
        css={{ height: "auto", minWidth: "100%" }}
        selectionMode="none"
      >
        <TableHeader columns={columns}>
          {(column) => (
            <TableColumn key={column.uid} align={column.uid === "actions" ? "center" : "start"}>
              {column.name}
            </TableColumn>
          )}
        </TableHeader>
        <TableBody items={businesses}>
          {(item) => (
            <TableRow key={item._id}>
              {(columnKey) => <TableCell>{renderCell(item, columnKey)}</TableCell>}
            </TableRow>
          )}
        </TableBody>
      </Table>

      <Modal isOpen={isCreateModalOpen} onClose={() => setIsCreateModalOpen(false)}>
        <ModalContent>
          <ModalHeader className="flex flex-col gap-1">Create New Business</ModalHeader>
          <ModalBody>
            <Input
              label="Business Name"
              placeholder="Enter business name"
              value={newBusiness.name}
              onChange={(e) => setNewBusiness({ ...newBusiness, name: e.target.value })}
            />
            <Textarea
              label="Description"
              placeholder="Enter business description"
              value={newBusiness.description}
              onChange={(e) => setNewBusiness({ ...newBusiness, description: e.target.value })}
            />
            <Input
              label="Zip Code"
              placeholder="Enter zip code"
              value={newBusiness.zipCode}
              onChange={(e) => setNewBusiness({ ...newBusiness, zipCode: e.target.value })}
            />
          </ModalBody>
          <ModalFooter>
            <Button color="danger" variant="light" onPress={() => setIsCreateModalOpen(false)}>
              Cancel
            </Button>
            <Button color="primary" onPress={handleCreateBusiness}>
              Create Business
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={isEditModalOpen} onClose={() => setIsEditModalOpen(false)}>
        <ModalContent>
          <ModalHeader>
            <h2>Edit Business</h2>
          </ModalHeader>
          <ModalBody className="flex flex-col gap-4">
            <Input
              label="Business Name"
              placeholder="Enter business name"
              value={editingBusiness?.name || ''}
              onChange={(e) => setEditingBusiness({ ...editingBusiness, name: e.target.value })}
            />
            <Select
              label="Connected Facebook Page"
              placeholder="Select a Facebook page"
              selectedKeys={editingBusiness?.facebookAccessId ? [editingBusiness.facebookAccessId] : []}
              onChange={(e) => setEditingBusiness({ 
                ...editingBusiness, 
                facebookAccessId: e.target.value 
              })}
              className="w-full"
            >
              {facebookAccesses.map((access) => (
                <SelectItem key={access._id} value={access._id}>
                  {access.name || 'Unnamed Page'}
                </SelectItem>
              ))}
            </Select>
            <Textarea
              label="Description"
              placeholder="Enter business description"
              value={editingBusiness?.description || ''}
              onChange={(e) => setEditingBusiness({ ...editingBusiness, description: e.target.value })}
            />
            <Input
              label="Website"
              placeholder="Enter business website"
              value={editingBusiness?.website || ''}
              onChange={(e) => setEditingBusiness({ ...editingBusiness, website: e.target.value })}
            />
            <Input
              label="Facebook URL"
              placeholder="Enter Facebook URL"
              value={editingBusiness?.facebookURL || ''}
              onChange={(e) => setEditingBusiness({ ...editingBusiness, facebookURL: e.target.value })}
            />
            <Input
              label="Instagram URL"
              placeholder="Enter Instagram URL"
              value={editingBusiness?.instagramURL || ''}
              onChange={(e) => setEditingBusiness({ ...editingBusiness, instagramURL: e.target.value })}
            />
            <Input
              label="X (Twitter) URL"
              placeholder="Enter X URL"
              value={editingBusiness?.XURL || ''}
              onChange={(e) => setEditingBusiness({ ...editingBusiness, XURL: e.target.value })}
            />
            <Input
              label="Zip Code"
              placeholder="Enter zip code"
              value={editingBusiness?.zipCode || ''}
              onChange={(e) => setEditingBusiness({ ...editingBusiness, zipCode: e.target.value })}
            />
          </ModalBody>
          <ModalFooter>
            <Button color="danger" variant="light" onPress={() => setIsEditModalOpen(false)}>
              Cancel
            </Button>
            <Button color="primary" onPress={handleEditBusiness}>
              Update Business
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default Businesses;
