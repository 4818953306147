import React, { useState, useEffect } from 'react';
import { Table, TableHeader, TableColumn, TableBody, TableRow, TableCell, Button, Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Input } from '@nextui-org/react';
import api from '../api'; // Centralized Axios instance
import { useNavigate } from 'react-router-dom';

const Emails = () => {
  const [emails, setEmails] = useState([]);
  const [status, setStatus] = useState('idle');
  const [error, setError] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newEmail, setNewEmail] = useState({ subject: '', content: '' });
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [emailToDelete, setEmailToDelete] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchEmails = async () => {
      setStatus('loading');
      try {
        const response = await api.get('/api/newsletters');
        console.log('Fetched emails:', response.data.data); // Add this line
        setEmails(response.data.data);
        setStatus('succeeded');
      } catch (err) {
        setError(err.message);
        setStatus('failed');
      }
    };

    if (status === 'idle') {
      fetchEmails();
    }
  }, [status]);

  const handleCreateEmail = async () => {
    try {
      const emailData = {
        ...newEmail,
        status: 'draft'
      };
      const response = await api.post('/api/newsletters', emailData);
      if (response.status === 201) {
        setEmails([...emails, response.data.newsletter]);
        setNewEmail({ subject: '', content: '' });
        setIsModalOpen(false);
      }
    } catch (error) {
      console.error('Failed to create the email:', error);
    }
  };

  const handleDeleteClick = (email, e) => {
    e.stopPropagation(); // Prevent row click navigation
    setEmailToDelete(email);
    setDeleteModalOpen(true);
  };

  const handleDelete = async () => {
    try {
      await api.delete(`/api/newsletters/${emailToDelete._id}`);
      setEmails(emails.filter(email => email._id !== emailToDelete._id));
      setDeleteModalOpen(false);
      setEmailToDelete(null);
    } catch (error) {
      console.error('Failed to delete email:', error);
      setError('Failed to delete email');
    }
  };

  if (status === 'loading') return <div>Loading...</div>;
  if (status === 'failed') return <div style={{ color: 'red' }}>{error}</div>;

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
        <h1>Emails</h1>
        <Button color="primary" auto onClick={() => setIsModalOpen(true)}>
          Create New Email
        </Button>
      </div>
      <Table aria-label="List of emails">
        <TableHeader>
          <TableColumn>Subject</TableColumn>
          <TableColumn>Status</TableColumn>
          <TableColumn>Created At</TableColumn>
          <TableColumn>Sent At</TableColumn>
          <TableColumn>Actions</TableColumn>
        </TableHeader>
        <TableBody>
          {emails.map((email) => (
            <TableRow 
              key={email._id} 
              onClick={() => navigate(`/emails/${email._id}`)}
              className="cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-800"
            >
              <TableCell>{email.subject || 'No Subject'}</TableCell>
              <TableCell>{email.status || 'draft'}</TableCell>
              <TableCell>{new Date(email.createdAt).toLocaleDateString()}</TableCell>
              <TableCell>{email.sentAt ? new Date(email.sentAt).toLocaleDateString() : 'Not sent'}</TableCell>
              <TableCell>
                <Button 
                  color="danger" 
                  size="sm" 
                  onClick={(e) => handleDeleteClick(email, e)}
                  className="min-w-unit-16"
                >
                  Delete
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <Modal isOpen={deleteModalOpen} onClose={() => setDeleteModalOpen(false)}>
        <ModalContent>
          <ModalHeader className="flex flex-col gap-1">Confirm Delete</ModalHeader>
          <ModalBody>
            Are you sure you want to delete this email?
            {emailToDelete && <p>Subject: {emailToDelete.subject}</p>}
          </ModalBody>
          <ModalFooter>
            <Button color="default" variant="light" onClick={() => setDeleteModalOpen(false)}>
              Cancel
            </Button>
            <Button color="danger" onClick={handleDelete}>
              Delete
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <ModalContent>
          <ModalHeader className="flex flex-col gap-1">Create New Email</ModalHeader>
          <ModalBody>
            <Input
              label="Subject"
              placeholder="Enter email subject"
              value={newEmail.subject}
              onChange={(e) => setNewEmail({ ...newEmail, subject: e.target.value })}
            />
            <Input
              label="Content"
              placeholder="Enter email content"
              value={newEmail.content}
              onChange={(e) => setNewEmail({ ...newEmail, content: e.target.value })}
            />
          </ModalBody>
          <ModalFooter>
            <Button color="danger" variant="light" onClick={() => setIsModalOpen(false)}>
              Cancel
            </Button>
            <Button color="primary" onClick={handleCreateEmail}>
              Create
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default Emails;
