import React from 'react';

const Dashboard = () => {
  return (
    <div>
      {/* Dashboard content will go here */}
    </div>
  );
};

export default Dashboard;