import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit';
import api from '../api';

export const fetchPosts = createAsyncThunk(
  'posts/fetchPosts',
  async (collectionId) => {
    const response = await api.get(`/api/content-collections/${collectionId}/posts`);
    return response.data.data;
  }
);

export const fetchFacebookPosts = createAsyncThunk(
  'posts/fetchFacebookPosts',
  async (collectionId) => {
    const response = await api.post(`/api/content-collections/${collectionId}/scan-facebook-posts`);
    return response.data.posts;
  }
);

export const translatePosts = createAsyncThunk(
  'posts/translatePosts',
  async ({ collectionId, aiOutputFieldPrompt, aiTypeFieldPrompt }, { getState }) => {
    const { posts } = getState();
    const response = await api.post(`/api/content-collections/${collectionId}/translate-posts`, {
      posts: posts.items,
      aiOutputFieldPrompt,
      aiTypeFieldPrompt
    });
    return response.data;
  }
);

export const updatePostFinalCopy = createAsyncThunk(
  'posts/updateFinalCopy',
  async ({ postId, finalCopy }) => {
    const response = await api.patch(`/api/posts/${postId}`, { finalCopy });
    return response.data;
  }
);

export const setPostFormOpen = createAction('posts/setPostFormOpen');
export const setEditingPost = createAction('posts/setEditingPost');

export const updatePost = createAsyncThunk(
  'posts/updatePost',
  async (postData) => {
    const response = await api.put(`/api/posts/${postData._id}`, postData);
    return response.data;
  }
);

export const createPost = createAsyncThunk(
  'posts/createPost',
  async (postData, { rejectWithValue }) => {
    try {
      const response = await api.post(`/api/content-collections/${postData.collectionId}/posts`, postData);
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const postsSlice = createSlice({
  name: 'posts',
  initialState: {
    items: [],
    status: 'idle',
    error: null,
    isTranslateModalOpen: false,
    aiOutputFieldPrompt: 'Turn the below rawBizContent info into a one sentence summary of the post. Always start with the sentence with the business name. The business name should be hyperlinked to the business website in HTML. The summary should be very matter of fact about the subject of the post. For example, Mountain Magic is having a 20% sale on kale microgreens through Thursday.\n\nRaw Business Content: {{rawBizContent}}\n\nBusiness Name: {{businessName}}\n\nBusiness Website: {{bizWebsite}}',
    aiTypeFieldPrompt: 'Interpret the below post to be either an Event, Sale, Promo, or Update. Your output should be only one of these 4 words. Make this interpretation based on the following definitions:\n\nEvent: talks about an upcoming ocassion.\nSale: describes a percentage or dollar amount off of a category, item or service.\nPromo: describes a limited time offer that customers can take advantage of that is not a percentage or dollar amount off of a category, item or service. For example, buy 2 and get the third free, a sweepstakes, a contest.\nUpdate: anything that does not fit the other categories. This is somewhat of a catch all category. But consider the other categories first before marking something as an update. Examples of updates might include, announcing a retirement, the availability of a new product or service that doesn\'t include a promotion or sale, reflecting on a past event that is not upcoming, a personal event that is not an official event of the business, an opportunity to pre-order something.',
    isPostFormOpen: false,
    editingPost: null,
  },
  reducers: {
    setTranslateModalOpen(state, action) {
      state.isTranslateModalOpen = action.payload;
    },
    setAIOutputFieldPrompt(state, action) {
      state.aiOutputFieldPrompt = action.payload;
    },
    setAITypeFieldPrompt(state, action) {
      state.aiTypeFieldPrompt = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPosts.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchPosts.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.items = action.payload;
      })
      .addCase(fetchPosts.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(fetchFacebookPosts.fulfilled, (state, action) => {
        state.items = [...state.items, ...action.payload];
      })
      .addCase(translatePosts.fulfilled, (state, action) => {
        state.items = action.payload;
      })
      .addCase(updatePostFinalCopy.fulfilled, (state, action) => {
        const index = state.items.findIndex(post => post._id === action.payload._id);
        if (index !== -1) {
          state.items[index] = action.payload;
        }
      })
      .addCase(setPostFormOpen, (state, action) => {
        state.isPostFormOpen = action.payload;
      })
      .addCase(setEditingPost, (state, action) => {
        state.editingPost = action.payload;
      })
      .addCase(updatePost.fulfilled, (state, action) => {
        const index = state.items.findIndex(post => post._id === action.payload._id);
        if (index !== -1) {
          state.items[index] = action.payload;
        }
      })
      .addCase(createPost.fulfilled, (state, action) => {
        state.items.push(action.payload);
      });
  },
});

export const { setTranslateModalOpen, setAIOutputFieldPrompt, setAITypeFieldPrompt } = postsSlice.actions;

export default postsSlice.reducer;
