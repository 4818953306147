import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Input, Textarea, Button, Card, Dropdown, DropdownTrigger, DropdownMenu, DropdownItem, Chip, Select, SelectItem } from "@nextui-org/react";
import api from '../api';
import { debounce } from 'lodash';
import DOMPurify from 'dompurify';
import { getTags } from '../api/tags';

const fadeInAnimation = {
  '@keyframes fadeIn': {
    '0%': { opacity: 0 },
    '100%': { opacity: 1 },
  },
  '.fade-in': {
    animation: 'fadeIn 0.5s ease-in-out',
  },
};

const EmailEditor = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [email, setEmail] = useState({
    subject: '',
    content: '',
    status: '',
    template: null,
    collectionId: null,
  });
  const [templates, setTemplates] = useState([]);
  const [collections, setCollections] = useState([]);
  const [error, setError] = useState('');
  const [saveStatus, setSaveStatus] = useState('');
  const [tags, setTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState(new Set());

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [emailResponse, templatesResponse, collectionsResponse, tagsResponse] = await Promise.all([
          api.get(`/api/newsletters/${id}`),
          api.get('/api/email-templates'),
          api.get('/api/content-collections'),
          getTags()
        ]);
        const emailData = emailResponse.data.data;
        const templatesData = templatesResponse.data.data;
        const collectionsData = collectionsResponse.data.data;
        const tagsData = tagsResponse;

        setEmail({
          subject: emailData.subject || '',
          content: emailData.content || '',
          status: emailData.status || '',
          template: emailData.template?._id || null,
          collectionId: emailData.collectionId || null,
        });
        
        if (emailData.tags) {
          const tagSet = new Set(emailData.tags.map(tag => 
            typeof tag === 'string' ? tag : tag._id
          ));
          setSelectedTags(tagSet);
        }
        
        setTemplates(templatesData || []);
        setCollections(collectionsData || []);
        setTags(tagsData || []);
      } catch (error) {
        console.error('Error fetching data:', error);
        setError('Failed to fetch email data');
      }
    };

    fetchData();
  }, [id]);

  const autoSave = useCallback(debounce(async (updatedEmail) => {
    try {
      await api.put(`/api/newsletters/${id}`, {
        ...updatedEmail,
        tags: Array.from(selectedTags)
      });
      console.log('Auto-saved successfully');
      setSaveStatus('saved');
      setTimeout(() => setSaveStatus(''), 2000);
    } catch (error) {
      console.error('Error auto-saving:', error);
      setError('Failed to auto-save changes');
    }
  }, 1000), [id, selectedTags]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEmail(prevEmail => ({
      ...prevEmail,
      [name]: value
    }));
  };

  useEffect(() => {
    if (email.subject || email.content) {
      autoSave(email);
    }
  }, [email, selectedTags]);

  const handleTemplateChange = async (templateId) => {
    if (templateId === '') {
      setEmail(prevEmail => ({
        ...prevEmail,
        template: null,
      }));
    } else {
      try {
        const response = await api.get(`/api/email-templates/${templateId}`);
        const templateData = response.data.data;
        setEmail(prevEmail => ({
          ...prevEmail,
          template: templateId,
          content: templateData.htmlContent,
        }));
      } catch (error) {
        console.error('Error fetching template:', error);
        setError('Failed to load template');
      }
    }
  };

  const handleCollectionChange = async (collectionId) => {
    setEmail(prevEmail => ({
      ...prevEmail,
      collectionId: collectionId === '' ? null : collectionId,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    try {
      await api.put(`/api/newsletters/${id}`, {
        ...email,
        tags: Array.from(selectedTags)
      });
      navigate('/emails');
    } catch (error) {
      console.error('Error updating email:', error);
      setError(error.response?.data?.message || 'Failed to update email');
    }
  };

  const handleSend = async () => {
    try {
      const response = await api.post(`/api/newsletters/${id}/send`, {
        tags: Array.from(selectedTags)
      });
      setEmail(prev => ({
        ...prev,
        status: 'sent'
      }));
      navigate('/emails');
    } catch (error) {
      console.error('Error sending email:', error);
      setError('Failed to send email: ' + (error.response?.data?.message || error.message));
    }
  };

  return (
    <div className="flex gap-4">
      <Card className="flex-1 p-4">
        <div className="flex items-center justify-between mb-4">
          <div className="flex items-center">
            <h2 className="text-2xl font-bold">Edit Email</h2>
            <span className={`ml-4 text-sm text-success ${saveStatus === 'saved' ? 'fade-in' : 'opacity-0'}`}>
              Saved
            </span>
          </div>
          <Button 
            color="primary"
            className="px-4"
            onClick={handleSend}
            disabled={email.status === 'sent'}
          >
            Send Email
          </Button>
        </div>
        {error && (
          <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4" role="alert">
            <strong className="font-bold">Error: </strong>
            <span className="block sm:inline">{error}</span>
          </div>
        )}
        <div>
          <div className="mb-4">
            <div className="flex flex-col gap-2">
              <label className="text-sm font-medium text-gray-700">Tags</label>
              <Select
                selectionMode="multiple"
                placeholder="Select tags"
                selectedKeys={selectedTags}
                onSelectionChange={setSelectedTags}
                className="max-w-full"
              >
                {tags.map((tag) => (
                  <SelectItem key={tag._id} value={tag._id}>
                    {tag.name}
                  </SelectItem>
                ))}
              </Select>
            </div>
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700 mb-2">Subject</label>
            <Input
              label="Subject"
              name="subject"
              value={email.subject}
              onChange={handleChange}
              className="mb-4"
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700 mb-2">Content</label>
            <Textarea
              label="Content"
              name="content"
              value={email.content}
              onChange={handleChange}
              className="mb-4"
              minRows={5}
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700 mb-2">Status</label>
            <Chip
              color={email.status === 'draft' ? 'warning' : email.status === 'sent' ? 'success' : 'default'}
              variant="flat"
            >
              {email.status || 'Unknown'}
            </Chip>
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">Template</label>
            <Dropdown>
              <DropdownTrigger>
                <Button 
                  variant="bordered" 
                  className="mt-1 w-full"
                >
                  {email.template && templates.length > 0
                    ? templates.find(t => t._id === email.template)?.name || "Select a template"
                    : "Select a template"}
                </Button>
              </DropdownTrigger>
              <DropdownMenu 
                aria-label="Select a template"
                onAction={(key) => handleTemplateChange(key)}
                selectedKeys={email.template ? [email.template] : []}
              >
                <DropdownItem key="">No template</DropdownItem>
                {templates.map((template) => (
                  <DropdownItem key={template._id}>{template.name}</DropdownItem>
                ))}
              </DropdownMenu>
            </Dropdown>
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">Collection</label>
            <Select
              label="Select collection"
              selectionMode="single"
              placeholder="Select a collection"
              selectedKeys={email.collectionId ? [email.collectionId] : []}
              onSelectionChange={(keys) => {
                const selectedKey = Array.from(keys)[0];
                handleCollectionChange(selectedKey);
              }}
              className="max-w-full"
            >
              <SelectItem key="" value="">No collection</SelectItem>
              {collections.map((collection) => (
                <SelectItem key={collection._id} value={collection._id}>
                  {collection.name}
                </SelectItem>
              ))}
            </Select>
          </div>
        </div>
      </Card>
      <Card className="flex-1 p-4">
        <h2 className="text-2xl font-bold mb-4">Preview</h2>
        <div className="border p-4 rounded-lg">
          <div 
            className="prose max-w-none"
            dangerouslySetInnerHTML={{ 
              __html: DOMPurify.sanitize(email.content, {
                ALLOWED_TAGS: ['p', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'strong', 'em', 'u', 'ol', 'ul', 'li', 'a', 'br', 'div', 'span'],
                ALLOWED_ATTR: ['href', 'target', 'style', 'class']
              })
            }}
          />
        </div>
      </Card>
    </div>
  );
};

export default EmailEditor;
