import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { fetchNewsletters, deleteNewsletter } from '../store/newslettersSlice';
import { Table, Button, Modal } from "@nextui-org/react";

function Newsletters() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const newsletters = useSelector((state) => state.newsletters.items || []);
  const loading = useSelector((state) => state.newsletters.status === 'loading');
  const error = useSelector((state) => state.newsletters.error);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [newsletterToDelete, setNewsletterToDelete] = useState(null);

  useEffect(() => {
    dispatch(fetchNewsletters());
  }, [dispatch]);

  const handleAddNewsletter = () => {
    navigate('/new-newsletter');
  };

  const handleDeleteClick = (newsletter) => {
    setNewsletterToDelete(newsletter);
    setDeleteModalOpen(true);
  };

  const handleDeleteConfirm = () => {
    if (newsletterToDelete) {
      dispatch(deleteNewsletter(newsletterToDelete._id))
        .unwrap()
        .then(() => {
          setDeleteModalOpen(false);
          setNewsletterToDelete(null);
        })
        .catch((err) => {
          console.error('Error deleting newsletter:', err);
        });
    }
  };

  const columns = [
    { key: 'subject', label: 'Subject' },
    { key: 'status', label: 'Status' },
    { key: 'createdAt', label: 'Created At' },
    { key: 'sentAt', label: 'Sent At' },
    { key: 'actions', label: 'Actions' },
  ];

  const rows = newsletters.map((newsletter) => ({
    key: newsletter._id,
    subject: newsletter.subject || 'No Subject',
    status: newsletter.status || 'Unknown',
    createdAt: newsletter.createdAt
      ? new Date(newsletter.createdAt).toLocaleDateString()
      : 'N/A',
    sentAt: newsletter.sentAt
      ? new Date(newsletter.sentAt).toLocaleDateString()
      : 'Not sent',
    actions: newsletter,
  }));

  if (loading) {
    return <div>Loading newsletters...</div>;
  }

  if (error) {
    return <div style={{ color: 'red' }}>Error: {error}</div>;
  }

  return (
    <div style={{ padding: '20px' }}>
      <h2>Newsletters</h2>
      <Button onClick={handleAddNewsletter} color="primary" auto>
        Add Newsletter
      </Button>
      <Table
        aria-label="List of newsletters"
        css={{ minWidth: '100%', marginTop: '20px' }}
      >
        <Table.Header columns={columns}>
          {(column) => (
            <Table.Column key={column.key}>{column.label}</Table.Column>
          )}
        </Table.Header>
        <Table.Body items={rows}>
          {(item) => (
            <Table.Row key={item.key}>
              {(columnKey) => {
                const cellValue = item[columnKey];
                if (columnKey === 'actions') {
                  return (
                    <Table.Cell>
                      <Button
                        color="error"
                        size="sm"
                        auto
                        onClick={() => handleDeleteClick(item.actions)}
                      >
                        Delete
                      </Button>
                    </Table.Cell>
                  );
                } else {
                  return (
                    <Table.Cell
                      onClick={() =>
                        navigate(`/newsletter-editor/${item.key}`)
                      }
                      css={{ cursor: 'pointer' }}
                    >
                      {cellValue}
                    </Table.Cell>
                  );
                }
              }}
            </Table.Row>
          )}
        </Table.Body>
      </Table>

      <Modal
        open={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        closeButton
      >
        <Modal.Header>
          <h3>Confirm Delete</h3>
        </Modal.Header>
        <Modal.Body>
          <p>
            Are you sure you want to delete the newsletter "
            {newsletterToDelete?.subject}"?
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button auto flat onClick={() => setDeleteModalOpen(false)}>
            Cancel
          </Button>
          <Button auto color="error" onClick={handleDeleteConfirm}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Newsletters;
