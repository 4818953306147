import api from './index'; // Import the centralized Axios instance

// Fetch all tags
export const getTags = async () => {
  try {
    const response = await api.get('/api/tags');
    return response.data; // Returns the tags array directly
  } catch (error) {
    console.error('Error fetching tags:', error);
    throw error;
  }
};

// Create a new tag
export const createTag = async (tagData) => {
  try {
    const response = await api.post('/api/tags', tagData);
    return response.data; // Returns the created tag
  } catch (error) {
    console.error('Error creating tag:', error);
    throw error;
  }
};
