"use client";

import {Accordion, AccordionItem} from "@nextui-org/react";
import React from "react";
import {Listbox, ListboxItem} from "@nextui-org/react";
import {Icon} from "@iconify/react";
import {cn} from "@nextui-org/react";
import { NavLink } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';

export const SidebarItemType = {
  Nest: "nest"
};

const Sidebar = React.forwardRef(({ items = [], onItemClick }, ref) => {
  const { checkPermission } = useAuth();

  const renderItem = (item) => {
    if (item.permission && !checkPermission(item.permission)) {
      return null;
    }

    if (item.type === SidebarItemType.Nest && item.items?.length > 0) {
      return (
        <Accordion key={item.key}>
          <AccordionItem 
            key={item.key} 
            aria-label={item.title}
            title={
              <div className="flex items-center gap-2">
                {item.icon && (
                  <Icon
                    className="text-default-500"
                    icon={item.icon}
                    width={24}
                  />
                )}
                <span>{item.title}</span>
              </div>
            }
          >
            <Listbox>
              {item.items.map((subItem) => renderItem(subItem))}
            </Listbox>
          </AccordionItem>
        </Accordion>
      );
    }

    return (
      <ListboxItem key={item.key}>
        <NavLink
          to={item.href}
          className={({ isActive }) =>
            cn("flex items-center gap-2 px-2 py-1", { "text-blue-500": isActive })
          }
          onClick={() => onItemClick && onItemClick()}
        >
          {item.icon && (
            <Icon
              className="text-default-500 group-data-[selected=true]:text-foreground"
              icon={item.icon}
              width={24}
            />
          )}
          <span>{item.title}</span>
          {item.endContent && <div className="ml-auto">{item.endContent}</div>}
        </NavLink>
      </ListboxItem>
    );
  };

  return (
    <nav className={cn("list-none")} ref={ref}>
      <Listbox
        hideSelectedIcon
        variant="flat"
        aria-label="Sidebar menu"
      >
        {items.map((item) => renderItem(item))}
      </Listbox>
    </nav>
  );
});

Sidebar.displayName = "Sidebar";

export default Sidebar;