import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../api'; // Centralized Axios instance

// Async thunk to fetch emails
export const fetchEmails = createAsyncThunk('emails/fetchEmails', async () => {
  const response = await api.get('/api/newsletters');
  return response.data.data;
});

const emailSlice = createSlice({
  name: 'emails',
  initialState: {
    items: [],
    status: 'idle',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchEmails.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchEmails.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.items = action.payload;
      })
      .addCase(fetchEmails.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default emailSlice.reducer;

