import React, { useState, useEffect } from 'react';
import { Avatar, Button, ScrollShadow, Spacer, Tooltip, cn } from "@nextui-org/react";
import { Icon } from "@iconify/react";
import { useMediaQuery } from "usehooks-ts";
import { Outlet, useNavigate } from "react-router-dom";

import Sidebar from "./sidebar";
import { items } from "./sidebar-items";
import { useAuth } from "../../hooks/useAuth";
import Logo from "./Logo";
import api from '../../api';

export default function SidebarWrapper() {
  const isMobile = useMediaQuery('(max-width: 640px)');
  const [isExpanded, setIsExpanded] = useState(!isMobile);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const { user: authUser, logout } = useAuth();
  const [user, setUser] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    setIsExpanded(!isMobile);
  }, [isMobile]);

  useEffect(() => {
    const fetchUserData = async () => {
      if (authUser?.id) {
        try {
          const response = await api.get(`/api/users/${authUser.id}`);
          console.log('API Response:', response.data);
          setUser(response.data);
        } catch (error) {
          console.error('Error fetching user data:', error);
        }
      }
    };
  
    fetchUserData();
  }, [authUser?.id]);

  console.log('Complete user state:', user);

  const handleLogout = () => {
    logout();
    navigate("/login");
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const handleItemClick = () => {
    if (isMobile) {
      setIsMobileMenuOpen(false);
    }
  };

  console.log('User data:', {
    avatar: user?.avatar,
    firstName: user?.firstName,
    lastName: user?.lastName
  });

  return (
    <div className="flex min-h-screen">
      {/* Mobile Header */}
      {isMobile && (
        <header className="fixed top-0 left-0 right-0 h-16 bg-white dark:bg-gray-900 border-b border-divider flex items-center justify-between px-4 z-50">
          <Logo size={32} />
          <button onClick={toggleMobileMenu} className="p-2">
            <Icon icon="solar:hamburger-menu-linear" width={24} />
          </button>
        </header>
      )}

      {/* Sidebar */}
      <div
        className={cn(
          "fixed top-0 left-0 h-screen border-r border-divider transition-all duration-300 ease-in-out z-40 bg-white dark:bg-gray-900",
          {
            "w-16": !isExpanded && !isMobile,
            "w-72": isExpanded && !isMobile,
            "w-72 -translate-x-full": isMobile && !isMobileMenuOpen,
            "translate-x-0": isMobileMenuOpen && isMobile,
          }
        )}
      >
        <div className="flex h-full flex-col p-6">
          {/* Logo and App Name (hidden on mobile) */}
          {!isMobile && (
            <>
              <div
                className={cn("flex items-center gap-3 px-3", {
                  "justify-center gap-0": !isExpanded,
                })}
              >
                <Logo size={32} />
                {isExpanded && (
                  <span className="text-small font-bold uppercase opacity-100">
                    Erik's Local Biz List
                  </span>
                )}
              </div>
              <Spacer y={8} />
            </>
          )}

          {/* User Info */}
          <div className="flex items-center gap-3 px-3">
            {console.log('Avatar URL:', user?.avatar)}
            <Avatar
              isBordered
              className="flex-none"
              size="sm"
              radius="lg"
              src={user?.avatar}
              name={`${user?.firstName || ''} ${user?.lastName || ''}`}
              showFallback
              fallback={<Icon className="text-default-500" icon="solar:user-outline" width={20} />}
            />
            {isExpanded && user && (
              <div className="flex max-w-full flex-col">
                <p className="truncate text-small font-medium text-default-600">
                  {user.email}
                </p>
                <p className="truncate text-tiny text-default-400">
                  {user.firstName ? `${user.firstName} ${user.lastName || ''}` : 'User'}
                </p>
              </div>
            )}
          </div>

          {/* Sidebar Navigation */}
          <ScrollShadow className="h-full max-h-full py-6">
            <Sidebar items={items} onItemClick={handleItemClick} />
          </ScrollShadow>

          {/* Logout and Help Buttons */}
          <div
            className={cn("mt-auto flex flex-col", {
              "items-center": !isExpanded,
            })}
          >
            <Tooltip content="Help & Information" isDisabled={isExpanded} placement="right">
              <Button
                className={cn(
                  "justify-start text-default-500 hover:text-foreground",
                  {
                    "justify-center": !isExpanded,
                  }
                )}
                isIconOnly={!isExpanded}
                variant="light"
              >
                {!isExpanded ? (
                  <Icon
                    className="text-default-500"
                    icon="solar:info-circle-line-duotone"
                    width={24}
                  />
                ) : (
                  <>
                    <Icon
                      className="flex-none text-default-500"
                      icon="solar:info-circle-line-duotone"
                      width={24}
                    />
                    <span className="ml-2">Help & Information</span>
                  </>
                )}
              </Button>
            </Tooltip>
            <Tooltip content="Log Out" isDisabled={isExpanded} placement="right">
              <Button
                onPress={handleLogout}
                className={cn(
                  "justify-start text-default-500 hover:text-foreground",
                  {
                    "justify-center": !isExpanded,
                  }
                )}
                isIconOnly={!isExpanded}
                variant="light"
              >
                {!isExpanded ? (
                  <Icon
                    className="rotate-180 text-default-500"
                    icon="solar:minus-circle-line-duotone"
                    width={24}
                  />
                ) : (
                  <>
                    <Icon
                      className="flex-none rotate-180 text-default-500"
                      icon="solar:minus-circle-line-duotone"
                      width={24}
                    />
                    <span className="ml-2">Log Out</span>
                  </>
                )}
              </Button>
            </Tooltip>
          </div>
        </div>
      </div>

      {/* Main Content Area */}
      <div 
        className={cn("flex-1 p-4 transition-all duration-300 ease-in-out", {
          "ml-16": !isExpanded && !isMobile,
          "ml-72": isExpanded && !isMobile,
          "mt-16": isMobile,
        })}
      >
        <main className="mt-4 h-full w-full overflow-visible">
          <Outlet />
        </main>
      </div>

      {/* Overlay for mobile menu */}
      <div 
        className={cn(
          "fixed inset-0 bg-black transition-opacity duration-300 ease-in-out z-30",
          {
            "opacity-50 pointer-events-auto": isMobile && isMobileMenuOpen,
            "opacity-0 pointer-events-none": !isMobileMenuOpen || !isMobile,
          }
        )}
        onClick={toggleMobileMenu}
      ></div>
    </div>
  );
}
