import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../api';

// Async thunk to fetch newsletters
export const fetchNewsletters = createAsyncThunk(
  'newsletters/fetchNewsletters',
  async () => {
    const response = await api.get('/api/newsletters');
    return response.data.data;
  }
);

// Async thunk to create a new newsletter
export const createNewsletter = createAsyncThunk(
  'newsletters/createNewsletter',
  async (newsletterData, { rejectWithValue }) => {
    try {
      const response = await api.post('/api/newsletters', newsletterData);
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Async thunk to fetch a single newsletter
export const fetchNewsletter = createAsyncThunk(
  'newsletters/fetchNewsletter',
  async (id) => {
    const response = await api.get(`/api/newsletters/${id}`);
    return response.data.data;
  }
);

// Async thunk to update a newsletter
export const updateNewsletter = createAsyncThunk(
  'newsletters/updateNewsletter',
  async (newsletterData) => {
    const response = await api.put(`/api/newsletters/${newsletterData._id}`, newsletterData);
    return response.data.data;
  }
);

// Async thunk to delete a newsletter
export const deleteNewsletter = createAsyncThunk(
  'newsletters/deleteNewsletter',
  async (id) => {
    await api.delete(`/api/newsletters/${id}`);
    return id;
  }
);

const newslettersSlice = createSlice({
  name: 'newsletters',
  initialState: {
    items: [],
    current: null,
    status: 'idle',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchNewsletters.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchNewsletters.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.items = action.payload;
      })
      .addCase(fetchNewsletters.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error?.message || 'Failed to fetch newsletters';
      })
      .addCase(createNewsletter.fulfilled, (state, action) => {
        state.items.push(action.payload);
      })
      .addCase(fetchNewsletter.fulfilled, (state, action) => {
        state.current = action.payload;
      })
      .addCase(updateNewsletter.fulfilled, (state, action) => {
        const index = state.items.findIndex(newsletter => newsletter._id === action.payload._id);
        if (index !== -1) {
          state.items[index] = action.payload;
        }
      })
      .addCase(deleteNewsletter.fulfilled, (state, action) => {
        state.items = state.items.filter(newsletter => newsletter._id !== action.payload);
      });
  },
});

export default newslettersSlice.reducer;
