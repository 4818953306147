import AWS from 'aws-sdk';

// Configure AWS SDK
const s3 = new AWS.S3({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  region: process.env.REACT_APP_AWS_REGION
});

/**
 * Uploads an avatar image to S3 and returns the URL.
 * @param {File} file - The image file to upload.
 * @returns {Promise<string>} - The URL of the uploaded image.
 */
export async function uploadAvatarToS3(file) {
  if (!file) {
    throw new Error('No file provided for upload');
  }

  const params = {
    Bucket: 'localbizlistbucket', // Specify your bucket name here
    Key: `manual-uploads/avatars/${Date.now()}_${file.name}`, // Updated folder path
    Body: file,
    ContentType: file.type
    // Remove the ACL parameter
  };

  try {
    const data = await s3.upload(params).promise();
    return data.Location; // Return the URL of the uploaded image
  } catch (error) {
    console.error('Error uploading file to S3:', error);
    throw new Error('Failed to upload file to S3');
  }
}
