import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import api from '../api'; // Import the centralized Axios instance
import { Card, CardBody, CardFooter, Button, Divider, Modal, ModalContent, ModalHeader, ModalBody, ModalFooter } from "@nextui-org/react";

const Templates = () => {
  const [templates, setTemplates] = useState([]);
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [templateToDelete, setTemplateToDelete] = useState(null);

  useEffect(() => {
    fetchTemplates();
  }, []);

  const fetchTemplates = async () => {
    try {
      const response = await api.get('/api/email-templates');
      setTemplates(response.data.data || []);
    } catch (error) {
      console.error('Error fetching templates:', error);
      setError(
        error.response?.data?.message || 'Failed to fetch templates'
      );
    }
  };

  const handleNewTemplate = () => {
    navigate('/templates/new');
  };

  const handleDeleteClick = (template) => {
    setTemplateToDelete(template);
    setDeleteModalOpen(true);
  };

  const handleDeleteConfirm = async () => {
    if (templateToDelete) {
      try {
        await api.delete(`/api/email-templates/${templateToDelete._id}`);
        setTemplates(templates.filter(t => t._id !== templateToDelete._id));
        setDeleteModalOpen(false);
        setTemplateToDelete(null);
      } catch (error) {
        console.error('Error deleting template:', error);
        setError(error.response?.data?.message || 'Failed to delete template');
      }
    }
  };

  return (
    <div className="container mx-auto p-4">
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-2xl font-bold">Email Templates</h1>
        <Button color="primary" onClick={handleNewTemplate}>
          New Template
        </Button>
      </div>
      {error && <p className="text-red-500 mb-4">{error}</p>}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {templates.map((template) => (
          <Card key={template._id} className="max-w-[400px]">
            <CardBody>
              <h2 className="text-lg font-semibold">{template.name}</h2>
              <p className="text-sm text-gray-500">{template.subject}</p>
              <div className="mt-2 text-sm">
                {template.htmlContent.length > 100 
                  ? template.htmlContent.substring(0, 100) + '...' 
                  : template.htmlContent}
              </div>
            </CardBody>
            <Divider />
            <CardFooter>
              <div className="flex justify-between w-full">
                <Link to={`/templates/edit/${template._id}`}>
                  <Button color="primary" size="sm">
                    Edit
                  </Button>
                </Link>
                <Button color="danger" size="sm" onClick={() => handleDeleteClick(template)}>
                  Delete
                </Button>
              </div>
            </CardFooter>
          </Card>
        ))}
      </div>

      <Modal isOpen={deleteModalOpen} onClose={() => setDeleteModalOpen(false)}>
        <ModalContent>
          <ModalHeader>Confirm Delete</ModalHeader>
          <ModalBody>
            Are you sure you want to delete the template "{templateToDelete?.name}"?
          </ModalBody>
          <ModalFooter>
            <Button color="default" onClick={() => setDeleteModalOpen(false)}>
              Cancel
            </Button>
            <Button color="danger" onClick={handleDeleteConfirm}>
              Delete
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default Templates;
