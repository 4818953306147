import api from '../api';

class AuthService {
  async login(email, password) {
    try {
      const response = await api.post('/api/auth/login', {
        email,
        password
      });

      if (response.data.token) {
        localStorage.setItem('authToken', response.data.token);
        return response.data;
      }
      throw new Error('No token received');
    } catch (error) {
      console.error('Login error:', error);
      throw error;
    }
  }

  logout() {
    localStorage.removeItem('authToken');
  }

  getCurrentUser() {
    const token = localStorage.getItem('authToken');
    return token;
  }

  async verifyToken() {
    const token = this.getCurrentUser();
    if (!token) return null;

    try {
      const response = await api.get('/api/auth/verify', {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        credentials: 'include'
      });

      if (!response.data) {
        this.logout();
        return null;
      }

      return response.data.user;
    } catch (error) {
      console.error('Token verification error:', error);
      this.logout();
      return null;
    }
  }

  async register(email, password) {
    try {
      const response = await api.post('/api/auth/register', {
        email,
        password
      }, {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        credentials: 'include'
      });

      if (response.data.token) {
        localStorage.setItem('authToken', response.data.token);
        return { success: true, user: response.data.user };
      }
      throw new Error('No token received');
    } catch (error) {
      console.error('Registration error:', error);
      throw error;
    }
  }
}

export default new AuthService();
