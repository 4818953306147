import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../api';

export const fetchBusinesses = createAsyncThunk(
  'businesses/fetchBusinesses',
  async () => {
    const response = await api.get('/api/businesses');
    return response.data.data;
  }
);

export const createBusiness = createAsyncThunk(
  'businesses/createBusiness',
  async (businessData) => {
    const response = await api.post('/api/businesses', businessData);
    return response.data.data;
  }
);

export const updateBusiness = createAsyncThunk(
  'businesses/updateBusiness',
  async ({ id, businessData }) => {
    const response = await api.put(`/api/businesses/${id}`, businessData);
    return response.data.data;
  }
);

export const deleteBusiness = createAsyncThunk(
  'businesses/deleteBusiness',
  async (id) => {
    await api.delete(`/api/businesses/${id}`);
    return id;
  }
);

const businessesSlice = createSlice({
  name: 'businesses',
  initialState: {
    items: [],
    status: 'idle',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchBusinesses.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchBusinesses.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.items = action.payload;
      })
      .addCase(fetchBusinesses.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(createBusiness.fulfilled, (state, action) => {
        state.items.push(action.payload);
      })
      .addCase(updateBusiness.fulfilled, (state, action) => {
        const index = state.items.findIndex(business => business._id === action.payload._id);
        if (index !== -1) {
          state.items[index] = action.payload;
        }
      })
      .addCase(deleteBusiness.fulfilled, (state, action) => {
        state.items = state.items.filter(business => business._id !== action.payload);
      });
  },
});

export default businessesSlice.reducer;