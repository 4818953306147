import React, { useState } from 'react';
import { Card, Input, Button } from "@nextui-org/react";
import { useSearchParams, useNavigate } from 'react-router-dom';
import api from '../api';

export default function ResetPassword() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const token = searchParams.get('token');
  
  const [formData, setFormData] = useState({
    newPassword: '',
    confirmPassword: ''
  });
  const [status, setStatus] = useState('idle');
  const [message, setMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (formData.newPassword !== formData.confirmPassword) {
      setMessage('Passwords do not match');
      setStatus('error');
      return;
    }

    setStatus('loading');

    try {
      await api.post('/api/auth/reset-password', {
        token,
        newPassword: formData.newPassword
      });
      
      setMessage('Password reset successful! Redirecting to login...');
      setStatus('success');
      setTimeout(() => navigate('/login'), 2000);
    } catch (error) {
      setMessage(error.response?.data?.message || 'An error occurred');
      setStatus('error');
    }
  };

  if (!token) {
    return (
      <div className="flex min-h-screen items-center justify-center bg-background">
        <Card className="w-full max-w-sm p-6">
          <p className="text-danger text-center">Invalid reset link</p>
        </Card>
      </div>
    );
  }

  return (
    <div className="flex min-h-screen items-center justify-center bg-background">
      <Card className="w-full max-w-sm p-6">
        <h1 className="text-xl font-medium mb-4">Set New Password</h1>
        
        {status === 'success' ? (
          <p className="text-success text-center">{message}</p>
        ) : (
          <form onSubmit={handleSubmit} className="flex flex-col gap-4">
            <Input
              label="New Password"
              type="password"
              value={formData.newPassword}
              onChange={(e) => setFormData({ ...formData, newPassword: e.target.value })}
              required
            />
            
            <Input
              label="Confirm Password"
              type="password"
              value={formData.confirmPassword}
              onChange={(e) => setFormData({ ...formData, confirmPassword: e.target.value })}
              required
            />
            
            {status === 'error' && (
              <p className="text-danger text-sm">{message}</p>
            )}
            
            <Button 
              color="primary" 
              type="submit"
              isLoading={status === 'loading'}
            >
              Reset Password
            </Button>
          </form>
        )}
      </Card>
    </div>
  );
}
