import React from 'react';
import { Button } from '@nextui-org/react';

const Toolbar = ({ onCreateItem, onFetchFacebookPosts, onTranslateAllPosts }) => {
  return (
    <div className="flex justify-start items-center mb-4 space-x-2">
      <Button color="default" variant="flat" onClick={onCreateItem}>
        Create Post
      </Button>
      <Button color="default" variant="flat" onClick={onFetchFacebookPosts}>
        Fetch Facebook Posts
      </Button>
      <Button color="default" variant="flat" onClick={onTranslateAllPosts}>
        Translate Posts
      </Button>
    </div>
  );
};

export default Toolbar;
