import React, { useState, useEffect } from 'react';
import { Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, Input, Textarea, Checkbox, Dropdown, DropdownTrigger, DropdownMenu, DropdownItem, Autocomplete, AutocompleteItem } from "@nextui-org/react";
import api from '../api';

const POST_TYPES = ['sale', 'promo', 'event', 'update'];

const PostForm = ({ isOpen, onClose, onSubmit, initialData = {} }) => {
  const [formData, setFormData] = useState({});
  const [businesses, setBusinesses] = useState([]);

  useEffect(() => {
    setFormData(initialData || {});
    fetchBusinesses();
  }, [initialData]);

  const fetchBusinesses = async () => {
    try {
      const response = await api.get('/api/businesses');
      setBusinesses(response.data.data);
    } catch (error) {
      console.error('Error fetching businesses:', error);
    }
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleTypeChange = (value) => {
    setFormData((prevData) => ({
      ...prevData,
      type: value,
    }));
  };

  const handleBusinessChange = (businessId) => {
    setFormData((prevData) => ({
      ...prevData,
      businessId: businessId,
    }));
  };

  const handleSubmit = () => {
    const submissionData = {
      ...formData,
      businessId: formData.businessId || null,
      include: formData.include || false,
      type: formData.type || '',
      rawBizContent: formData.rawBizContent || ''
    };
    console.log('Submitting form data:', submissionData);
    onSubmit(submissionData);
  };

  return (
    <Modal 
      isOpen={isOpen} 
      onClose={onClose}
      scrollBehavior="inside"
      placement="center"
      classNames={{
        base: "max-h-[90vh] overflow-visible",
        wrapper: "items-start",
      }}
    >
      <ModalContent>
        {(onClose) => (
          <>
            <ModalHeader className="flex flex-col gap-1">
              {formData && formData._id ? 'Edit Post' : 'Create New Post'}
            </ModalHeader>
            <ModalBody>
              <div className="flex flex-col gap-4">
                <Autocomplete
                  label="Business"
                  placeholder="Select a business"
                  defaultSelectedKey={formData?.businessId}
                  onSelectionChange={handleBusinessChange}
                >
                  {businesses.map((business) => (
                    <AutocompleteItem key={business._id} value={business._id}>
                      {business.name}
                    </AutocompleteItem>
                  ))}
                </Autocomplete>
                
                <Checkbox
                  name="include"
                  isSelected={formData?.include || false}
                  onChange={handleChange}
                >
                  Include
                </Checkbox>
                
                <Dropdown>
                  <DropdownTrigger>
                    <Button variant="bordered" className="w-full justify-start">
                      Type: {formData?.type || 'Select'}
                    </Button>
                  </DropdownTrigger>
                  <DropdownMenu 
                    aria-label="Post Type Selection" 
                    onAction={handleTypeChange}
                  >
                    {POST_TYPES.map((type) => (
                      <DropdownItem key={type}>{type}</DropdownItem>
                    ))}
                  </DropdownMenu>
                </Dropdown>

                <Textarea
                  label="Raw Biz Content"
                  name="rawBizContent"
                  value={formData?.rawBizContent || ''}
                  onChange={handleChange}
                  placeholder="Enter raw business content"
                />
              </div>
            </ModalBody>
            <ModalFooter>
              <Button color="danger" variant="light" onPress={onClose}>
                Cancel
              </Button>
              <Button color="primary" onPress={handleSubmit}>
                {formData && formData._id ? 'Update' : 'Create'}
              </Button>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  );
};

export default PostForm;
