import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';

const PrivateRoute = ({ children }) => {
  const { user, loading, verifyToken } = useAuth();

  useEffect(() => {
    console.log('PrivateRoute useEffect - loading:', loading, 'user:', user); // Debugging line
    if (!loading && !user) {
      verifyToken();
    }
  }, [loading, user, verifyToken]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return user ? children : <Navigate to="/login" replace />;
};

export default PrivateRoute;