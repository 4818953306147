import { createSlice } from '@reduxjs/toolkit';

const emailModalSlice = createSlice({
  name: 'emailModal',
  initialState: {
    isModalOpen: false,
    newEmail: {
      subject: '',
      content: '',
    },
  },
  reducers: {
    openModal: (state) => {
      state.isModalOpen = true;
    },
    closeModal: (state) => {
      state.isModalOpen = false;
    },
    setNewEmail: (state, action) => {
      state.newEmail = { ...state.newEmail, ...action.payload };
    },
    resetNewEmail: (state) => {
      state.newEmail = { subject: '', content: '' };
    },
  },
});

export const { openModal, closeModal, setNewEmail, resetNewEmail } = emailModalSlice.actions;
export default emailModalSlice.reducer;

