import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Input, Button, Card, CardBody, Spacer, Dropdown, DropdownTrigger, DropdownMenu, DropdownItem, Select, SelectItem } from "@nextui-org/react";
import api from '../api';
import { uploadAvatarToS3 } from '../utils/s3';
import { getTags } from '../api/tags';

function NewUser() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const mode = searchParams.get('mode') || 'create';
  const userId = searchParams.get('id');
  
  const [formData, setFormData] = useState({
    email: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
    address: {
      street: '',
      city: '',
      state: '',
      zipCode: '',
      country: ''
    },
    birthday: '',
    signupSource: 'website',
    avatar: ''
  });
  const [tags, setTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState(new Set([]));
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(mode === 'edit');

  useEffect(() => {
    if (mode === 'edit' && userId) {
      const fetchUser = async () => {
        try {
          const response = await api.get(`/api/users/${userId}`);
          const userData = response.data;
          console.log('Fetched user data:', userData);
          
          setFormData({
            ...userData,
            birthday: userData.birthday ? new Date(userData.birthday).toISOString().split('T')[0] : '',
            address: userData.address || {
              street: '',
              city: '',
              state: '',
              zipCode: '',
              country: ''
            }
          });
          
          if (userData.tags && Array.isArray(userData.tags)) {
            setSelectedTags(new Set(userData.tags));
          }
        } catch (error) {
          console.error('Error fetching user:', error);
          setError('Failed to fetch user data: ' + (error.response?.message || error.message));
        } finally {
          setLoading(false);
        }
      };

      fetchUser();
    }

    const fetchTags = async () => {
      try {
        const tags = await getTags();
        setTags(tags);
      } catch (error) {
        console.error('Failed to fetch tags:', error);
      }
    };

    fetchTags();
  }, [mode, userId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name.includes('address.')) {
      const addressField = name.split('.')[1];
      setFormData(prev => ({
        ...prev,
        address: {
          ...prev.address,
          [addressField]: value
        }
      }));
    } else {
      setFormData(prev => ({ ...prev, [name]: value }));
    }
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      console.log('File selected:', file.name);
      try {
        const avatarUrl = await uploadAvatarToS3(file);
        console.log('File uploaded successfully:', avatarUrl);
        setFormData(prev => ({ ...prev, avatar: avatarUrl }));
      } catch (error) {
        console.error('Failed to upload avatar:', error);
        setError('Failed to upload avatar: ' + error.message);
      }
    }
  };

  const handleSignupSourceChange = (value) => {
    setFormData(prev => ({ ...prev, signupSource: value }));
  };

  const handleTagSelection = (key) => {
    setSelectedTags(prev => [...prev, key]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const dataToSubmit = {
        ...formData,
        tags: Array.from(selectedTags)
      };
      
      if (mode === 'create') {
        await api.post('/api/users', dataToSubmit);
      } else {
        await api.put(`/api/users/${userId}`, dataToSubmit);
      }
      navigate('/users');
    } catch (error) {
      setError(`Failed to ${mode === 'create' ? 'create' : 'update'} user: ` + (error.response?.data?.message || error.message));
    }
  };

  if (loading) return <div>Loading...</div>;

  return (
    <Card css={{ maxWidth: "600px", margin: "0 auto", padding: "20px" }}>
      <CardBody>
        <h2>{mode === 'create' ? 'Add New User' : 'Edit User'}</h2>
        {error && <p style={{ color: 'red' }}>{error}</p>}
        <form onSubmit={handleSubmit}>
          <Input label="Email" type="email" name="email" value={formData.email} onChange={handleChange} required fullWidth />
          <Spacer y={1.5} />
          <Input label="First Name" type="text" name="firstName" value={formData.firstName} onChange={handleChange} required fullWidth />
          <Spacer y={1.5} />
          <Input label="Last Name" type="text" name="lastName" value={formData.lastName} onChange={handleChange} required fullWidth />
          <Spacer y={1.5} />
          <Input label="Phone Number" type="tel" name="phoneNumber" value={formData.phoneNumber} onChange={handleChange} fullWidth />
          <Spacer y={1.5} />
          <Input label="Street" type="text" name="address.street" value={formData.address.street} onChange={handleChange} fullWidth />
          <Spacer y={1.5} />
          <Input label="City" type="text" name="address.city" value={formData.address.city} onChange={handleChange} fullWidth />
          <Spacer y={1.5} />
          <Input label="State" type="text" name="address.state" value={formData.address.state} onChange={handleChange} fullWidth />
          <Spacer y={1.5} />
          <Input label="Zip Code" type="text" name="address.zipCode" value={formData.address.zipCode} onChange={handleChange} fullWidth />
          <Spacer y={1.5} />
          <Input label="Country" type="text" name="address.country" value={formData.address.country} onChange={handleChange} fullWidth />
          <Spacer y={1.5} />
          <Input label="Birthday" type="date" name="birthday" value={formData.birthday} onChange={handleChange} fullWidth />
          <Spacer y={1.5} />
          <div className="flex flex-col gap-2">
            <label className="text-sm font-medium">Avatar</label>
            <div className="flex items-center gap-2">
              {formData.avatar ? (
                <div className="flex items-center gap-4 w-full">
                  <div className="text-sm text-default-500">
                    Current: {formData.avatar.split('/').pop()}
                  </div>
                  <Button 
                    size="sm" 
                    variant="light"
                    onClick={() => document.getElementById('avatar-upload').click()}
                  >
                    Change Avatar
                  </Button>
                  <input
                    id="avatar-upload"
                    type="file"
                    accept="image/*"
                    onChange={handleFileChange}
                    style={{ display: 'none' }}
                  />
                </div>
              ) : (
                <Input 
                  type="file" 
                  accept="image/*" 
                  onChange={handleFileChange} 
                  fullWidth
                />
              )}
            </div>
          </div>
          <Spacer y={1.5} />
          <div className="flex flex-col gap-2">
            <label className="text-sm font-medium">Tags</label>
            <Select
              selectionMode="multiple"
              placeholder="Select tags"
              selectedKeys={selectedTags}
              onSelectionChange={setSelectedTags}
              className="max-w-full"
            >
              {tags.map((tag) => (
                <SelectItem key={tag._id} value={tag._id}>
                  {tag.name}
                </SelectItem>
              ))}
            </Select>
          </div>
          <Spacer y={1.5} />
          <div className="flex flex-col gap-2">
            <label className="text-sm font-medium">Signup Source</label>
            <Dropdown>
              <DropdownTrigger>
                <Button flat>{formData.signupSource}</Button>
              </DropdownTrigger>
              <DropdownMenu onAction={handleSignupSourceChange}>
                <DropdownItem key="website">Website</DropdownItem>
                <DropdownItem key="manual entry">Manual Entry</DropdownItem>
                <DropdownItem key="import">Import</DropdownItem>
                <DropdownItem key="other">Other</DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </div>
          <Spacer y={2} />
          <Button type="submit" color="primary" fullWidth>{mode === 'create' ? 'Add User' : 'Save Changes'}</Button>
        </form>
      </CardBody>
    </Card>
  );
}

export default NewUser;
