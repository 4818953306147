import React, { useState, useEffect } from 'react';
import { Table, TableHeader, TableBody, TableColumn, TableRow, TableCell, Button, Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Input } from "@nextui-org/react";
import { getTags, createTag } from '../api/tags';
import api from '../api';

function TagManager() {
  const [tags, setTags] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newTag, setNewTag] = useState({ name: '', description: '' });
  const [error, setError] = useState(null);
  const [editingTag, setEditingTag] = useState(null);

  useEffect(() => {
    const fetchTags = async () => {
      try {
        const tagsData = await getTags();
        setTags(tagsData);
      } catch (error) {
        console.error('Error fetching tags:', error);
      }
    };

    fetchTags();
  }, []);

  const handleAddTag = async () => {
    try {
      if (editingTag) {
        const updatedTag = await api.put(`/api/tags/${editingTag._id}`, newTag);
        setTags(tags.map(tag => tag._id === editingTag._id ? updatedTag : tag));
      } else {
        const createdTag = await createTag(newTag);
        setTags([...tags, createdTag]);
      }
      setIsModalOpen(false);
      setNewTag({ name: '', description: '' });
      setEditingTag(null);
    } catch (error) {
      setError('Failed to ' + (editingTag ? 'update' : 'create') + ' tag: ' + error.message);
    }
  };

  const handleEditClick = (tag) => {
    setEditingTag(tag);
    setNewTag({ name: tag.name, description: tag.description });
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setNewTag({ name: '', description: '' });
    setEditingTag(null);
  };

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <h1>Manage Tags</h1>
        <Button color="primary" onClick={() => {
          console.log('Opening modal');
          setIsModalOpen(true);
        }}>Add New Tag</Button>
      </div>
      <Table>
        <TableHeader>
          <TableColumn>Name</TableColumn>
          <TableColumn>Description</TableColumn>
          <TableColumn>Actions</TableColumn>
        </TableHeader>
        <TableBody>
          {tags.map(tag => (
            <TableRow key={tag._id}>
              <TableCell>{tag.name}</TableCell>
              <TableCell>{tag.description}</TableCell>
              <TableCell>
                <Button color="primary" variant="light" onPress={() => handleEditClick(tag)}>Edit</Button>
                <Button color="error">Delete</Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <Modal isOpen={isModalOpen} onClose={handleModalClose}>
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader>
                <h2>{editingTag ? 'Edit Tag' : 'Create New Tag'}</h2>
              </ModalHeader>
              <ModalBody>
                <Input
                  label="Name"
                  placeholder="Enter tag name"
                  value={newTag.name}
                  onChange={(e) => setNewTag({ ...newTag, name: e.target.value })}
                />
                <Input
                  label="Description"
                  placeholder="Enter tag description"
                  value={newTag.description}
                  onChange={(e) => setNewTag({ ...newTag, description: e.target.value })}
                />
              </ModalBody>
              <ModalFooter>
                <Button color="danger" variant="light" onPress={onClose}>
                  Cancel
                </Button>
                <Button color="primary" onPress={handleAddTag}>
                  {editingTag ? 'Update' : 'Create'}
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </div>
  );
}

export default TagManager;
