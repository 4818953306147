import { configureStore } from '@reduxjs/toolkit';
import postsReducer from './postsSlice';
import businessesReducer from './businessesSlice';
import newslettersReducer from './newslettersSlice';
import emailReducer from './emailSlice'; // Import the email reducer
import emailModalReducer from './emailModalSlice'; // Import the email modal reducer

export const store = configureStore({
  reducer: {
    posts: postsReducer,
    businesses: businessesReducer,
    newsletters: newslettersReducer,
    emails: emailReducer, // Add the email reducer
    emailModal: emailModalReducer, // Add the email modal reducer
  },
});
