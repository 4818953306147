import React, { useState, useEffect } from 'react';
import {
  Table,
  TableHeader,
  TableColumn,
  TableBody,
  TableRow,
  TableCell,
  User,
  Chip,
  Tooltip,
  Button
} from "@nextui-org/react";
import { Icon } from '@iconify/react';
import { getUsers } from '../api/users';
import { useAuth } from '../hooks/useAuth';
import { useNavigate } from 'react-router-dom';

const statusColorMap = {
  subscribed: 'success',
  unsubscribed: 'danger',
  pending: 'warning',
};

export default function Users() {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const { user } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        setLoading(true);
        const fetchedUsers = await getUsers();
        setUsers(fetchedUsers);
      } catch (error) {
        console.error('Error fetching users:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, []);

  const columns = [
    { name: "EMAIL", uid: "email" },
    { name: "STATUS", uid: "status" },
    { name: "SIGNUP SOURCE", uid: "signupSource" },
    { name: "DATE ADDED", uid: "dateAdded" },
    { name: "ACTIONS", uid: "actions" }
  ];

  const renderCell = (user, columnKey) => {
    const cellValue = user[columnKey];

    switch (columnKey) {
      case "email":
        return (
          <User
            avatarProps={{ radius: "lg", src: user.avatar || '/default-avatar.png' }}
            name={cellValue}
          >
            {cellValue}
          </User>
        );
      case "status":
        return (
          <Chip color={statusColorMap[user.status] || 'default'} size="sm" variant="flat">
            {user.status}
          </Chip>
        );
      case "signupSource":
        return cellValue || "N/A";
      case "dateAdded":
        return new Date(cellValue).toLocaleDateString();
      case "actions":
        return (
          <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            <Tooltip content="Edit">
              <Button isIconOnly variant="light" onClick={() => navigate(`/users/new?mode=edit&id=${user._id}`)}>
                <Icon icon="mdi:pencil" width={20} />
              </Button>
            </Tooltip>
            <Tooltip color="danger" content="Delete">
              <Button isIconOnly variant="light" color="danger" onClick={() => console.log('Delete user')}>
                <Icon icon="mdi:delete" width={20} />
              </Button>
            </Tooltip>
          </div>
        );
      default:
        return cellValue || "N/A";
    }
  };

  const handleAddUser = () => {
    navigate('/users/new');
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!Array.isArray(users) || users.length === 0) {
    console.error('Users data is not an array or is empty:', users);
    return <div>No users found or data format error.</div>;
  }

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '16px' }}>
        <Button color="primary" onClick={handleAddUser}>
          Add New User
        </Button>
      </div>
      <Table
        aria-label="Users Table"
        css={{ height: "auto", minWidth: "100%" }}
        selectionMode="none"
      >
        <TableHeader columns={columns}>
          {(column) => (
            <TableColumn key={column.uid}>{column.name}</TableColumn>
          )}
        </TableHeader>
        <TableBody items={users}>
          {(item) => (
            <TableRow key={item._id}>
              {(columnKey) => <TableCell>{renderCell(item, columnKey)}</TableCell>}
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  );
}
