import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { AuthProvider } from './hooks/useAuth';
import { Provider } from 'react-redux';
import { store } from './store';
import Login from './components/Login';
import SignUp from './components/SignUp';
import Dashboard from './components/Dashboard';
import NewBusiness from './components/NewBusiness';
import Users from './components/Users';
import NewUser from './components/NewUser';
import Businesses from './components/Businesses';
import Newsletters from './components/Newsletters';
import CollectionPosts from './components/CollectionPosts';
import { NextUIProvider } from '@nextui-org/react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import './styles/globals.css';

import SidebarWrapper from './components/Sidebar/SidebarWrapper';
import PrivateRoute from './components/PrivateRoute';
import PostEditor from './components/PostEditor';
import Templates from './components/Templates';
import TemplateEditor from './components/TemplateEditor';
import Emails from './components/Emails';
import EmailEditor from './components/EmailEditor';
import TagManager from './components/TagManager';
import ForgotPassword from './components/ForgotPassword';
import ResetPassword from './components/ResetPassword';


function App() {
  return (
    <Provider store={store}>
      <GoogleOAuthProvider clientId="YOUR_GOOGLE_CLIENT_ID">
        <NextUIProvider>
          <AuthProvider>
            <Router>
              <Routes>
                {/* Public Routes */}
                <Route path="/login" element={<Login />} />
                <Route path="/signup" element={<SignUp />} />
                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route path="/reset-password" element={<ResetPassword />} />

                {/* Protected Routes */}
                <Route
                  path="/"
                  element={
                    <PrivateRoute>
                      <SidebarWrapper />
                    </PrivateRoute>
                  }
                >
                  <Route path="dashboard" element={<Dashboard />} />
                  <Route path="users" element={<Users />} />
                  <Route path="users/new" element={<NewUser mode="create" />} />
                  <Route path="users/edit/:userId" element={<NewUser mode="edit" />} />
                  <Route path="businesses" element={<Businesses />} />
                  <Route path="newsletters" element={<Newsletters />} />
                  <Route path="collection-posts" element={<CollectionPosts />} />
                  <Route path="collection-posts/:collectionId" element={<PostEditor />} />
                  <Route path="templates" element={<Templates />} />
                  <Route path="templates/edit/:id" element={<TemplateEditor />} />
                  <Route path="templates/new" element={<TemplateEditor />} />
                  <Route path="emails" element={<Emails />} />
                  <Route path="emails/:id" element={<EmailEditor />} />
                  <Route path="tags" element={<TagManager />} />
                </Route>

                {/* Redirect any unknown routes */}
                <Route path="*" element={<Navigate to="/login" replace />} />
              </Routes>
            </Router>
          </AuthProvider>
        </NextUIProvider>
      </GoogleOAuthProvider>
    </Provider>
  );
}

export default App;
